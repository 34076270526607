/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
import {
  Button,
  Space,
  Collapse,
  DatePicker,
  Form,
  Input,
  Card,
  Popconfirm,
  message,
  Radio,
  Tooltip,
  Table,
  Select,
  Cascader,
} from "antd"
import React, {
  memo,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import "./index.scss"
import { InfoCircleOutlined } from "@ant-design/icons"
import { RexMinus, RexMinusNumber } from "../../../../../utils/RegExp"
import { handSettingOptions, settingOptions, settingOptionsPayment } from "../../constant"
import { debounce } from "lodash"
const { Panel } = Collapse
type ExpandIconPosition = "start" | "end"
const { Option } = Select

export const RateSetting = (props: FromProps) => {
  const {
    form,
    setPayment,
    payment,
    companyList,
    tissueList,
    setDataSource,
    dataSource,
    setHandSettingOptionsList,
    handSettingOptionsList,
  } = props
  const [newSettingOptions, setNewSettingOptions] = useState<any>([])

  const displayRender = (labels: string[]) => labels[labels.length - 1]

  const handleSpecNameChange = (index: number, value: string, type: string) => {
    const newValue = value
    const newSpecs = [...payment]
    newSpecs[index][type] = newValue
    setPayment(newSpecs)
  }
  const handPayee = (value, record, type) => {
    const payer = form.getFieldValue("specDetailsData")
    const payerFlag = payer?.some(
      (item: any) =>
        item?.payerRole === (item?.payeeRole?.length > 0 ? item?.payeeRole[0] : undefined)
    )
    record[type] = value
    if (payerFlag) {
      message.error("支付方收款方不能是同一选项")
    }
  }

  const columns = [
    {
      title: (
        <span>
          <span style={{ color: "red", marginRight: 3 }}>*</span>支付项
        </span>
      ),
      width: "25%",
      dataIndex: `payOption`,
      key: `payOption`,
      render: (text: string, record: any, idx: any) => (
        <Form.Item
          name={["specDetailsData", idx, "payOption"]}
          rules={[
            {
              required: true,
              message: "请选择",
            },
          ]}
        >
          <Select
            style={{ width: 255 }}
            placeholder="选择支付项"
            onDropdownVisibleChange={(visible) => {
              if (visible) {
                if (payment.length > 0) {
                  const _payment = payment.map((ie, index) => {
                    if (ie.name) {
                      return {
                        label: ie.name,
                        value: ie.name,
                      }
                    }
                  })
                  const newPayment = [...handSettingOptions, ..._payment.filter(Boolean)]
                  setHandSettingOptionsList(newPayment.filter(Boolean))
                } else {
                  setHandSettingOptionsList(handSettingOptions)
                }
              }
            }}
            onChange={(value: any, data: any) => {
              record.payOption = value
              record.customizePaymentItemName = data.children
            }}
          >
            {handSettingOptionsList &&
              handSettingOptionsList?.map((item) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        </Form.Item>
      ),
    },

    {
      title: (
        <span>
          <span style={{ color: "red", marginRight: 3 }}>*</span>付款方
        </span>
      ),
      dataIndex: `payerRole`,
      key: `payerRole`,
      width: "25%",
      render: (text: string, record: any, idx: any) => (
        <Form.Item
          name={["specDetailsData", idx, "payerRole"]}
          rules={[
            {
              required: true,
              message: "请选择",
            },
          ]}
        >
          <Select
            style={{ width: 255 }}
            placeholder="选择付款方"
            onChange={(value) => handPayee(value, record, "payerRole")}
          >
            {settingOptionsPayment &&
              settingOptionsPayment?.map((item) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          <span style={{ color: "red", marginRight: 3 }}>*</span>收款方
        </span>
      ),
      dataIndex: `payeeRole`,
      key: `payeeRole`,
      width: "25%",
      render: (text: string, record: any, idx: any) => (
        <Form.Item
          name={["specDetailsData", idx, "payeeRole"]}
          rules={[
            {
              required: true,
              message: "请选择",
            },
          ]}
        >
          <Cascader
            style={{ width: 255 }}
            placeholder="选择收款方"
            options={newSettingOptions}
            expandTrigger="hover"
            showSearch
            onChange={(value) => handPayee(value, record, "payeeRole")}
            displayRender={displayRender}
          />
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          <span style={{ color: "red", marginRight: 3 }}>*</span>
          <Tooltip placement="top" title={"排序数字越小，优先级越高，支付时先支付优先级高的款项"}>
            优先级
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </span>
      ),
      dataIndex: `sort`,
      key: `sort`,
      width: "25%",
      render: (text: string, record: any, idx: any) => (
        <Form.Item
          name={["specDetailsData", idx, "sort"]}
          rules={[
            {
              required: true,
              message: "请输入",
            },
          ]}
        >
          <Input
            style={{ width: 255 }}
            value={text}
            type="number"
            placeholder="请输入"
            min={0}
            onChange={(e) => {
              record.sort = e.target.value
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: "操作",
      dataIndex: "操作",
      render: (_, record) => (
        <a
          onClick={() => {
            const _specDetailsDataList = [...dataSource]
            const resultData = _specDetailsDataList?.filter((ie) => ie?.key !== record?.key)
            form.setFieldsValue({ specDetailsData: resultData })
            setDataSource(resultData)
          }}
        >
          删除
        </a>
      ),
    },
  ]
  useMemo(() => {
    const resultData = [
      {
        label: "指定公司",
        value: "TAX_COMPANY",
        children: tissueList || [],
      },
    ]
    const newArrTing = settingOptions.concat(resultData)
    setNewSettingOptions(newArrTing)
  }, [companyList, tissueList])
  return (
    <Card
      title="支付设定"
      style={{ marginTop: 16 }}
      extra={
        <>
          <Button
            type="primary"
            onClick={() => {
              // if (payment?.length == 3) {
              //   message.warning("最多添加3个规格项")
              //   return
              // }
              setPayment([...payment, { name: "" }])
            }}
          >
            添加支付项
          </Button>
        </>
      }
    >
      <div className="rateSetting">
        <span className="rateSetting-text">费率设定</span>
        <div className="rateSetting-component" style={{ paddingTop: 16 }}>
          <Form.Item<FieldType>
            labelCol={{ span: 8 }}
            label="增值税率"
            name="valueAddTaxRate"
            rules={[
              {
                required: true,
                message: "请输入增值税率",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "增值税率", 50, true), //特殊字符
              },
            ]}
          >
            <Input placeholder="请输入增值税率" style={{ maxWidth: "180px" }} suffix="%" />
          </Form.Item>
          {/* <Form.Item<FieldType>
        labelCol={{ span: 8 }}
        label="代扣增值税率"
        name="valueAddTaxCollectionRate"
        className="RateSetting_Collapse_time"
        rules={[
          {
            required: true,
            message: "请输入代扣增值税率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "代扣增值税率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入代扣增值税率" style={{ maxWidth: "180px" }} suffix="%" />
      </Form.Item> */}
          <Form.Item<FieldType>
            labelCol={{ span: 8 }}
            label="平台预扣费率"
            name="withholdTaxRate"
            rules={[
              {
                required: true,
                message: "请输入平台预扣费率",
              },
              {
                validator: async (rule: any, value: string) =>
                  inputDefaultRules(value, "平台预扣费率", 50, true), //特殊字符
              },
            ]}
          >
            <Input placeholder="请输入平台预扣费率" style={{ maxWidth: "180px" }} suffix="%" />
          </Form.Item>
          {/* <Form.Item<FieldType>
        className="incomeTaxRateReverse"
        label="（反向开票）预扣个税及附加费率"
        name="incomeTaxRateReverse"
        rules={[
          {
            required: true,
            message: "预扣个税及附加费率",
          },
          {
            validator: async (rule: any, value: string) =>
              inputDefaultRules(value, "预扣个税及附加费率", 50, true), //特殊字符
          },
        ]}
      >
        <Input placeholder="请输入预扣个税及附加费率" style={{ minWidth: "210px" }} suffix="%" />
      </Form.Item> */}
        </div>
        <div className="rateSetting-order">
          <span className="rateSetting-text" style={{ display: payment.length > 0 ? "" : "none" }}>
            订单支付项
          </span>
          {/* <div className="rateSetting-order-text">卖方净得价=不含税金额-平台预扣费</div> */}
        </div>
        <Form.Item>
          {payment?.map((spec: any, specIndex: any) => (
            <>
              <div
                key={specIndex}
                style={{ marginBottom: "16px", marginTop: 10 }}
                className="rateSetting-specification"
              >
                <div style={{ display: "flex" }}>
                  <div>
                    <span style={{ color: "#F5222DD9", marginRight: 3 }}>*</span>支付项名称
                    <Input
                      placeholder="支付项名称"
                      value={spec.name}
                      showCount
                      maxLength={15}
                      onChange={(e) => handleSpecNameChange(specIndex, e.target.value, "name")}
                      style={{ marginLeft: "8px", width: 285 }}
                    />
                  </div>
                  <Popconfirm
                    title={`确定删除吗？`}
                    onConfirm={() => {
                      // if (payment?.length == 1) {
                      //   message.error("至少保留1个")
                      //   return
                      // }
                      const updatedPayment = payment.filter((_, index) => index !== specIndex)
                      setPayment(updatedPayment)
                    }}
                  >
                    <Button type="text" danger style={{ marginBottom: "8px" }}>
                      删除
                    </Button>
                  </Popconfirm>

                  <div style={{ display: "flex", paddingLeft: 50, paddingTop: 5 }}>
                    <p style={{ width: 85 }}>
                      <Tooltip
                        placement="top"
                        title={
                          "价内金额：含在订单的含税金额中,价外金额：不含在订单含税金额中，属于单独计算的金额"
                        }
                      >
                        费用类型
                        <InfoCircleOutlined style={{ marginLeft: 5 }} />
                      </Tooltip>
                    </p>
                    <Radio.Group
                      value={spec.isInside}
                      onChange={(e) => handleSpecNameChange(specIndex, e.target.value, "isInside")}
                    >
                      <Radio value={false}>价外金额</Radio>
                      <Radio value={true}>价内金额</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div style={{ paddingTop: 20 }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p style={{ width: 110, paddingLeft: 0 }}>
                      <Tooltip
                        placement="top"
                        overlayStyle={{ maxWidth: "400px", wordWrap: "break-word" }}
                        title={
                          <div>
                            <div>不含税金额（Excluding Tax Amount）：XT</div>
                            <div>含税金额（Including Tax Amount）：IT</div>
                            <div>净得价（Net Price）：NP</div>
                            <div>增值税（Value-Added Tax）：VAT</div>
                            <div>平台预扣费（Platform Withholding Fee）：PWF</div>
                          </div>
                        }
                      >
                        计算公式
                        <InfoCircleOutlined style={{ marginLeft: 5 }} />
                      </Tooltip>
                    </p>
                    <Input
                      placeholder="请输入"
                      value={spec.formula}
                      onChange={(e) => handleSpecNameChange(specIndex, e.target.value, "formula")}
                      style={{ marginLeft: -25, width: 450, marginTop: -10 }}
                    />
                    {/* <span style={{ color: "#FF7300", marginLeft: 16, marginTop: -10 }}>
                      不含税金额A，含税金额B，净得价 C，增值税率：T，平台预扣费率：R
                    </span> */}
                  </div>
                </div>
              </div>
            </>
          ))}
        </Form.Item>
        <span className="rateSetting-text">支付路径</span>
        <div style={{ marginBottom: 16 }}></div>
        <Table
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          pagination={false}
          columns={columns}
        />
        <Button
          type="dashed"
          onClick={() => {
            const newData: any = {
              key: crypto?.randomUUID().slice(0, 10),
              payOption: null,
              payerRole: null,
              payeeRole: null,
              customizePaymentItemName: "",
              sort: "",
            }
            setDataSource([...dataSource, newData])
          }}
          block
          style={{
            maxWidth: "100%",
            marginTop: 16,
            height: 40,
            color: "rgba(24, 144, 255, 1)",
            marginBottom: 16,
          }}
        >
          + 新增支付路径
        </Button>
      </div>
    </Card>
  )
}

export default RateSetting
