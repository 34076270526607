/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Space,
  Collapse,
  DatePicker,
  Form,
  Input,
  Checkbox,
  Radio,
  Col,
  Select,
} from "antd"
import React, { memo, forwardRef, useImperativeHandle, useState, useEffect } from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import { CloseOutlined } from "@ant-design/icons"
import { orderDrawerOptions } from "../../constant"
import "./index.scss"
import { TextArea } from "form-render"
const { Panel } = Collapse
const { Option } = Select

export const RequestBiling = (props: FromProps) => {
  const { form } = props
  const [payeeOpen, setPayeeOpen] = useState<boolean>(false)
  const [reviewerOpen, setReviewerOpen] = useState<boolean>(false)
  const [notesOpen, setNotesOpen] = useState<boolean>(false)

  useEffect(() => {
    form.setFieldsValue({
      type: "SPECIAL",
      drawer: ["BUYER"],
    })
  }, [])
  return (
    <>
      <div className="RequestBiling">
        <Form.Item<FieldType> label="申请开票方" name="drawer">
          <Checkbox.Group options={orderDrawerOptions}></Checkbox.Group>
        </Form.Item>
        <div className="remarks">
          <Form.Item label="发票备注" name="remarks">
            <Checkbox.Group>
              <Checkbox value="displayBankAccount">显示购销方开户银行和银行帐号</Checkbox>
              <div>
                <Checkbox
                  value="displayPayee"
                  onChange={(e) => {
                    setPayeeOpen(e.target.checked)
                    if (!e.target.checked) {
                      form.setFieldValue("payeeName", "")
                    }
                  }}
                >
                  <Form.Item
                    name={"payeeName"}
                    label="显示收款人"
                    rules={[
                      {
                        required: payeeOpen,
                        message: "",
                      },
                    ]}
                  >
                    <Input placeholder="请输入收款人" />
                  </Form.Item>
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="displayReviewer"
                  onChange={(e) => {
                    setReviewerOpen(e.target.checked)
                    if (!e.target.checked) {
                      form.setFieldValue("reviewerName", "")
                    }
                  }}
                >
                  <Form.Item
                    name={"reviewerName"}
                    label="显示复核人"
                    rules={[
                      {
                        required: reviewerOpen,
                        message: "",
                      },
                    ]}
                  >
                    <Input placeholder="请输入复核人" />
                  </Form.Item>
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  value="displayRemark"
                  style={{ marginTop: 15 }}
                  onChange={(e) => {
                    setNotesOpen(e.target.checked)
                    if (!e.target.checked) {
                      form.setFieldValue("remark", "")
                    }
                  }}
                ></Checkbox>
                <Form.Item
                  style={{ marginTop: -23, marginLeft: 25 }}
                  name={"remark"}
                  label="备注"
                  rules={[
                    {
                      required: notesOpen,
                      message: "",
                    },
                  ]}
                >
                  <TextArea placeholder="请输入备注" style={{ width: 280 }} />
                </Form.Item>
              </div>
            </Checkbox.Group>
          </Form.Item>
        </div>

        <Form.Item<FieldType> name="type" label="允许的开票类型">
          <Radio.Group defaultValue="SPECIAL">
            <Radio value="SPECIAL">专票</Radio>
            <Radio value="GENERAL">普票</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[{ required: true, message: "请选择" }]}
          name="checkPayment"
          label="验证税费和附加税缴纳"
        >
          <Radio.Group>
            <Radio value={true}>需要验证</Radio>
            <Radio value={false}>不需要验证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[{ required: true, message: "请选择" }]}
          name="checkTaxPaid"
          label="验证货款缴纳"
        >
          <Radio.Group>
            <Radio value={true}>需要验证</Radio>
            <Radio value={false}>不需要验证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[{ required: true, message: "请选择" }]}
          name="checkLogistic"
          label="验证物流是否完成"
        >
          <Radio.Group>
            <Radio value={true}>需要验证</Radio>
            <Radio value={false}>不需要验证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[{ required: true, message: "请选择" }]}
          name="checkBuyerSign"
          label="验证购买方合同签署状态是否完成"
        >
          <Radio.Group>
            <Radio value={true}>需要验证</Radio>
            <Radio value={false}>不需要验证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          rules={[{ required: true, message: "请选择" }]}
          name="checkSellerSign"
          label="验证销售方合同签署状态是否完成"
        >
          <Radio.Group>
            <Radio value={true}>需要验证</Radio>
            <Radio value={false}>不需要验证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item<FieldType>
          label="单张发票最高限额"
          className="NewTradingScheme_Content_seller_remove"
          required
        >
          <Form.List initialValue={[undefined]} name={["invoiceLimit"]}>
            {(subFields, subOpt) => (
              <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                {subFields.map((subField) => (
                  <Space key={subField.key}>
                    {/* <Form.Item name={[subField.name, "taxRegionId"]}>
                      <Select style={{ width: 175 }} placeholder="选择税区">
                        {invoiceLimitsOptions &&
                          invoiceLimitsOptions.map((item) => {
                            return <Option value={item.value}>{item.label}</Option>
                          })}
                      </Select>
                    </Form.Item> */}
                    <Form.Item
                      name={[subField.name, "threeMax"]}
                      rules={[
                        {
                          required: true,
                          message: "单张最高开票限额",
                        },
                      ]}
                    >
                      <Input placeholder="单张最高开票限额(V3)" />
                    </Form.Item>
                    <Form.Item
                      name={[subField.name, "fourMax"]}
                      rules={[
                        {
                          required: true,
                          message: "单张最高开票限额",
                        },
                      ]}
                    >
                      <Input placeholder="单张最高开票限额(V4)" />
                    </Form.Item>
                    {/* <CloseOutlined
                      onClick={() => {
                        subOpt.remove(subField.name)
                      }}
                    /> */}
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => subOpt.add()}
                  block
                  style={{
                    maxWidth: 588,
                    color: "rgba(24, 144, 255, 1)",
                    display: subFields.length >= 1 ? "none" : "",
                    height: 40,
                  }}
                >
                  + 新增配置
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item<FieldType>
          name="approvalInvoice"
          label="编辑开票审核要求"
          rules={[
            {
              required: true,
              message: "请输入编辑开票审核要求",
            },
            {
              validator: async (rule: any, value: string) =>
                inputDefaultRules(value, "编辑开票审核要求", 2000), //特殊字符
            },
          ]}
        >
          <Input.TextArea
            placeholder="请输入2000字以内的中英文"
            style={{ width: 588, height: 88 }}
          />
        </Form.Item>
      </div>
    </>
  )
}

export default RequestBiling
