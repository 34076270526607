import { Button, Descriptions, Space, Tag, message } from "antd"
import React from "react"
import { connect } from "react-redux"
import ModalForm from "../../../../../../../components/ModalForm"
import {
  PaymentRecordOfflineForm,
  PaymentRecordOfflineFormType,
} from "../../../../../../../components/FormTemplate/paymentRecordOffline"
import { uploadOfflinePayment } from "../../../../../promises"
import moment from "moment"
import { validatePayments } from "../../utils"

export const offlinePayment = (props) => {
  const {
    context,
    roleListSeller,
    roleListCustomize,
    roleListTaxFees,
    useHiddenOpen,
    form,
    contentPayment,
  } = props
  return (
    <>
      <Space>
        <ModalForm<PaymentRecordOfflineFormType>
          onFinish={async (values: any) => {
            const result = Object.keys(values).map((key) => ({ ...values[key] }))
            console.log(values, "values")
            let _contentPayment =
              contentPayment == "线下付款" ? "付款金额不能为0" : "退款金额不能为0"
            let _validatePayments = validatePayments(values)
            if (!_validatePayments) {
              message.error(_contentPayment)
              return Promise.reject(new Error(_contentPayment))
            }
            const _paymentList = result?.filter((ie) => Number(ie?.amount) !== 0)
            _paymentList?.forEach(async (ie: any) => {
              await uploadOfflinePayment(context?.data && [context?.data.id], {
                channel: "EP",
                payOption: ie.payOption,
                customizePaymentItemName:
                  ie.payOption == "CUSTOMIZE" ? ie.customizePaymentItemName : null,
                amount: contentPayment == "线下付款" ? Number(ie?.amount) : Number(-ie?.amount),
                paidTime: moment(ie?.paidTime).format("YYYY-MM-DD 00:00:00"),
                payerName: ie?.payerName,
                payeeName: ie?.payeeName,
                receipts: [ie?.fileId],
              })
            })
            message.success("操作成功")
            setTimeout(() => {
              context?.refresh()
            }, 1000)
          }}
          fieldProps={{
            form: form,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
          }}
          modalProps={{ width: 650, destroyOnClose: true }}
          title={contentPayment}
          trigger={
            contentPayment == "线下付款" ? (
              <Button
                hidden={useHiddenOpen}
                style={{
                  background: "#1890FF",
                  color: "#fff",
                }}
              >
                {contentPayment}
              </Button>
            ) : (
              <Button hidden={useHiddenOpen} danger>
                {contentPayment}
              </Button>
            )
          }
        >
          <Descriptions size="small" column={1} style={{ paddingLeft: 35, paddingTop: 20 }}>
            <Descriptions.Item label="关联订单">
              <Tag
                color="#f4f7fc"
                style={{ color: "#000", borderColor: "#E9EDF6" }}
                key={context?.data.id}
              >
                {context?.data.id}
              </Tag>
            </Descriptions.Item>
          </Descriptions>

          {roleListSeller?.length == 1 ? (
            <PaymentRecordOfflineForm
              typeTitle="货款"
              filed="paymentOne"
              form={form}
              orders={[
                {
                  id: context?.data.id,
                  sellerName: context?.data.supplier?.name,
                  buyerName: context?.data.buyer?.name,
                },
              ]}
              roleList={roleListSeller}
              contentPayment={contentPayment}
            ></PaymentRecordOfflineForm>
          ) : null}

          {roleListTaxFees?.length == 1 ? (
            <PaymentRecordOfflineForm
              typeTitle="平台预扣费"
              filed="paymentThree"
              form={form}
              orders={[
                {
                  id: context?.data.id,
                  sellerName: context?.data.supplier?.name,
                  buyerName: context?.data.buyer?.name,
                },
              ]}
              roleList={roleListTaxFees}
              contentPayment={contentPayment}
            ></PaymentRecordOfflineForm>
          ) : null}
          {roleListCustomize?.map((ie: any, index: any) => {
            return (
              <>
                <PaymentRecordOfflineForm
                  form={form}
                  typeTitle={ie.customizePaymentItemName}
                  filed={"customize_" + index}
                  orders={[
                    {
                      id: context?.data.id,
                      sellerName: context?.data.supplier?.name,
                      buyerName: context?.data.buyer?.name,
                    },
                  ]}
                  roleList={[ie]}
                  contentPayment={contentPayment}
                ></PaymentRecordOfflineForm>
              </>
            )
          })}
        </ModalForm>
      </Space>
    </>
  )
}

export default offlinePayment
