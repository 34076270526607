/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Tag,
  message,
} from "antd"
import React, { useRef, memo, useEffect, useMemo, useState } from "react"
import { PlusOutlined } from "@ant-design/icons"
import numeral from "numeral"
import "./index.scss"
import moment, { Moment } from "moment"
import { useAsyncEffect, useMount, useRequest, useUpdate } from "ahooks"
import { OperableImage } from "../components/paymentInfoAbleImage/index"
import UtilOp from "../../../services/serviceOp"
import { OP_TRADE } from "../../../api/config/servicePort"
import { UploadFile } from "antd/lib/upload"
import { Result } from "@src/services/util"
import { cross_env } from "../../../services/serviceOp"
import { numberPointReg } from "@src/utils/RegExp.ts"
const utilOp = UtilOp.getInstance()

export const getOrdersReceivableAmount = (params: RequestData.Payment.OrdersReceivableAmount) => {
  return utilOp.httpGet(`${OP_TRADE}/orders/receivable`, params).then((res) => res.data)
}
const uploadFileAndGettingRes = async <T extends Record<string, string>>(file: UploadFile<T>) => {
  return new Promise<
    | Result<{ downloadLink: string; downloadLinkExpirationTime: string; filePath: string }>
    | undefined
  >((resolve, reject) => {
    if (file.status === "done") {
      resolve(file.response)
    } else if (file.status === "error") {
      message.success("更新失败")
      reject(file.error)
    }
  })
}
type OfflinePaymentUploadRequestData = RequestData.Payment.OfflinePaymentUpload["receipts"][0]
interface PaymentRecordOfflineFormValues extends Omit<OfflinePaymentUploadRequestData, "paidTime"> {
  paidTime: Moment
  fileUrl: string
}

export type PaymentRecordOfflineFormType = {
  receiptFormList: PaymentRecordOfflineFormValues[]
}
const defaultProps = {}
type props = {
  unpaidAmount?: number
  orders?: { id?: number; sellerName?: string; buyerName?: string }[]
  form?: FormInstance<PaymentRecordOfflineFormType> | any
  roleList?: any
  contentPayment: string
  typeTitle: string
  filed?: string
}

export type PaymentRecordOfflineFormProps = Required<typeof defaultProps> &
  props &
  React.HTMLAttributes<HTMLDivElement>
export const PaymentRecordOfflineForm: React.FC<
  React.PropsWithChildren<PaymentRecordOfflineFormProps>
> = memo((props) => {
  const { form, orders: orders, roleList, contentPayment, typeTitle, filed } = props
  const containerRef = useRef<HTMLDivElement>()
  const OrdersReceivableAmount = roleList && roleList[0]
  const amountPayment =
    contentPayment == "线下付款"
      ? OrdersReceivableAmount.remainReceivable
      : OrdersReceivableAmount.paidAmount
  const amountPaymentType = contentPayment == "线下付款" ? "付款" : "退款"
  console.log(OrdersReceivableAmount, "OrdersReceivableAmount")
  const [fileList, setFileList] = useState<any>([]) // 其他补充材料上传
  const [fileFlag, setFileFlag] = useState<any>(false) // 其他补充材料上传
  const [value, setValue] = useState<any>("")
  useEffect(() => {
    form.setFieldsValue({
      [filed as string]: {
        fileId: "",
        payerName: roleList[0]?.payerName,
        payeeName: roleList[0]?.payeeName,
        payOption: roleList[0]?.payOption,
        customizePaymentItemName: roleList[0]?.customizePaymentItemName,
      },
    })
  }, [])

  function disabledDate(current: any) {
    // 只允许选择今天之前的日期
    return current && current > moment().endOf("day")
  }
  const getRemainAmount = (value?: number) => {
    return value ? value : ""
  }

  const handValue = useMemo(() => {
    let num = getRemainAmount(amountPayment)
    form.setFieldsValue({
      [filed as string]: { amount: 0 },
    })
    setValue(num)
    return (
      <div className="payment_amount">
        <Form.Item
          name={[`${filed}`, "amount"]}
          label={amountPaymentType + "金额"}
          rules={[
            {
              required: false,
              message: "不可为零",
            },
            {
              validator: async (rule: any, values: string) => {
                if (Number(values) > Number(amountPayment)) {
                  return Promise.reject(`${amountPaymentType}金额不可超过${amountPayment}`)
                } else if (!numberPointReg.test(Number(values))) {
                  return Promise.reject(new Error("只能输入正整数"))
                }
              },
            },
          ]}
        >
          <Input
            placeholder="请输入"
            value={num}
            style={{ width: 300 }}
            onChange={(e) => {
              // setValue(e?.target?.value);
            }}
          ></Input>
        </Form.Item>
        <Form.Item name={[`${filed}`, "payerName"]} hidden={true}>
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item name={[`${filed}`, "payeeName"]} hidden={true}>
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item name={[`${filed}`, "payOption"]} hidden={true}>
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <Form.Item name={[`${filed}`, "customizePaymentItemName"]} hidden={true}>
          <Input placeholder="请输入"></Input>
        </Form.Item>
        <span className="amount_num">
          {amountPaymentType}金额不可超过{num || 0}
        </span>
      </div>
    )
  }, [OrdersReceivableAmount.data, value])
  const handFiles = useMemo(() => {
    setFileFlag(false)
    return (
      <>
        <Form.Item name={[`${filed}`, "fileId"]} label={"上传凭证"}>
          <OperableImage
            width={120}
            height={120}
            action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
            src={fileList}
            numInfo={10}
            setFileList={setFileList}
            setFileFlag={setFileFlag}
            uploadFileProps={{
              name: "file",
              accept: "image/png,image/jpeg",
              onChange: async ({ fileList }) => {
                setFileList(fileList)
                if (!fileList.some((file: any) => file.status !== "done")) {
                  let newFile = fileList.map((file) => file.response?.data.filePath)
                  form.setFieldsValue({
                    [filed as string]: { fileId: newFile.join(",") },
                  })
                }
              },
            }}
          >
            上传
          </OperableImage>
        </Form.Item>
      </>
    )
  }, [fileList, fileFlag])

  console.log(form.getFieldsValue())
  return (
    <>
      {amountPayment == 0 ? null : (
        <div className="offline-receipt-desc">
          <Descriptions size="small" column={1} style={{ paddingLeft: 50 }}>
            <div className="offline-text">
              {contentPayment == "线下付款" ? "应付" : "退款"}项：{typeTitle}
            </div>
            <Descriptions.Item> </Descriptions.Item>
            <Descriptions.Item style={{ paddingTop: 40 }} label={amountPaymentType + "方"}>
              {contentPayment == "线下付款"
                ? OrdersReceivableAmount?.payerName
                : OrdersReceivableAmount?.payeeName}
            </Descriptions.Item>
            <Descriptions.Item> </Descriptions.Item>
            <Descriptions.Item label="收款方">
              {contentPayment == "线下付款"
                ? OrdersReceivableAmount?.payeeName
                : OrdersReceivableAmount?.payerName}
            </Descriptions.Item>
            <Descriptions.Item> </Descriptions.Item>
          </Descriptions>
          {handValue}
          <Form.Item
            name={[`${filed}`, "paidTime"]}
            label={amountPaymentType + "时间"}
            rules={[{ required: true, message: "请选择" }]}
          >
            <DatePicker
              placeholder="请选择"
              format="YYYY-MM-DD" // 设置日期格式
              disabledDate={disabledDate}
              style={{ width: 300 }}
            ></DatePicker>
          </Form.Item>
          {handFiles}
        </div>
      )}
    </>
  )
})
PaymentRecordOfflineForm.displayName = "线下支付上传表单"
PaymentRecordOfflineForm.defaultProps = defaultProps
