import { TagCustomStyleProps } from "@/components/TagCustomStyle"
import { BadgeProps } from "antd"
import { PresetStatusColorType } from "antd/es/_util/colors"

export const OrderPaymentStatusEnum: Record<API.Order.PaymentStatus, TagCustomStyleProps> = {
  COMPLETED: {
    status: "success",
    text: "已付款",
  },
  PARTIAL_PAID: {
    status: "warning",
    text: "部分付款",
  },
  PENDING: {
    status: "error",
    text: "未完成",
  },
  CLOSE: {
    status: "default",
    text: "已关闭",
  },
}

export const ContractStatusEnum: Record<Exclude<API.SignStatus, "REVOKED">, TagCustomStyleProps> = {
  NO_SIGNATURE: {
    status: "default",
    text: "无需签署",
  },
  ALL_COMPLETED: {
    status: "success",
    text: "已签署",
  },
  BUYER_COMPLETED: {
    status: "warning",
    text: "卖方未签署",
  },
  SELLER_COMPLETED: {
    status: "warning",
    text: "买方未签署",
  },
  PENDING: {
    status: "error",
    text: "未签署",
  },
  REVOKED: {
    status: "default",
    text: "已撤销",
  },
}

export const SupplyContractEffectiveEnum: Record<0 | 1, TagCustomStyleProps> = {
  0: {
    status: "error",
    text: "已失效",
  },
  1: {
    status: "success",
    text: "生效中",
  },
}

export const SupplyContractStatusEnum: Record<
  Exclude<API.SupplyContractStatus, "SELLER_NO_SIGN" | "BUYER_NO_SIGN">,
  TagCustomStyleProps
> = {
  SIGNED: {
    status: "success",
    text: "已签署",
  },
  NO_SIGN: {
    status: "error",
    text: "未签署",
  },
  SELLER_NO_SIGN: {
    status: "processing",
    text: "部分签署",
  },
  BUYER_NO_SIGN: {
    status: "processing",
    text: "部分签署",
  },
}

export const OrderLogisticsStatusEnum: Record<string, BadgeProps> = {
  未完成: {
    status: "error",
    text: "未完成",
  },
  已完成: {
    status: "success",
    text: "已完成",
  },
  部分发货: {
    status: "processing",
    text: "部分发货",
  },
}

export const OrderInvoiceStatusEnum: Record<string, BadgeProps> = {
  PENDING: {
    status: "error",
    text: "未申请",
  },
  PROCESSING: {
    status: "processing",
    text: "开票中",
  },
  COMPLETED: {
    status: "success",
    text: "已开票",
  },
  // FAILURE: {
  //   status: "error",
  //   text: "开票失败",
  // },
  // APPLIED: {
  //   status: "processing",
  //   text: "已申请",
  // },
}
export const examineStatusEnum: Record<
  string,
  {
    status: PresetStatusColorType
    text: string
  }
> = {
  NO_REVIEW: {
    status: "processing",
    text: "未审核",
  },
  PENDING: {
    status: "processing",
    text: "等待审核",
  },
  REVIEWING: {
    status: "processing",
    text: "审核中",
  },
  REVIEWED: {
    status: "success",
    text: "审核通过",
  },
  REFUSED: {
    status: "error",
    text: "审核驳回",
  },
}
export const TextStyle_OrderPaymentStatusEnum: Record<string, BadgeProps> = {
  COMPLETED: {
    status: "success",
    text: "已付款",
  },
  PARTIAL_PAID: {
    status: "warning",
    text: "部分付款",
  },
  PENDING: {
    status: "error",
    text: "待付款",
  },
}
export const TextStyle_OrderPaymentStatusNameEnum: Record<string, BadgeProps> = {
  已支付: {
    status: "success",
    text: "已付款",
  },
  部分支付: {
    status: "warning",
    text: "部分付款",
  },
  待支付: {
    status: "error",
    text: "待付款",
  },
}
export const TextStyle_ContractStatusEnum: Record<
  Exclude<string, "SELLER_NO_SIGN" | "BUYER_NO_SIGN">,
  BadgeProps
> = {
  已签署: {
    status: "success",
    text: "已签署",
  },
  未签署: {
    status: "error",
    text: "未签署",
  },
  买方未签署: {
    status: "warning",
    text: "部分签署",
  },
  卖方未签署: {
    status: "warning",
    text: "部分签署",
  },
}
export const TextStyle_OrderContractStatusEnum: Record<
  Exclude<string, "SELLER_NO_SIGN" | "BUYER_NO_SIGN">,
  BadgeProps
> = {
  已签署: {
    status: "success",
    text: "已签署",
  },
  未签署: {
    status: "error",
    text: "未签署",
  },
  部分签署: {
    status: "warning",
    text: "部分签署",
  },
}
export const PaymentItemStatusEnum: Record<string, BadgeProps> = {
  UNABLE: {
    status: "warning",
    text: "不可支付",
  },
  PEDDING: {
    status: "warning",
    text: "待支付",
  },
  PAYMENGING: {
    status: "warning",
    text: "支付中",
  },
  COMPLETED: {
    status: "success",
    text: "支付成功",
  },
  FAIL: {
    status: "error",
    text: "支付失败",
  },
  CLOSE: {
    status: "default",
    text: "支付关闭",
  },
}

export const PaymentItemTypeMap: Record<string, string> = {
  TAX_ORDER_AMOUNT: "订单含税价",
  SELLER_NET_AMOUNT: "卖家应收",
  TAX: "平台应收",
}
export const PayTypeEnumsMap: Record<string, string> = {
  OFFLINE: "线下支付",
  ONLINE: "线上支付",
  DEDUCTION: "预付款",
  PAYMENT_PLAN: "自动扣款",
}
