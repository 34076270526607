/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Space, Collapse, DatePicker, Form, Input, Checkbox, Select, Radio } from "antd"
import React, { memo, forwardRef, useImperativeHandle, useState, useEffect, useMemo } from "react"
import { FieldType, FromProps } from "../../interface"
import { inputDefaultRules } from "@src/utils/RegExp"
import { CloseOutlined, MinusCircleOutlined } from "@ant-design/icons"
import {
  contractStatusOptions,
  applyToOptions,
  priceTypeOptions,
  intervalTypeOptions,
  settlementLimit,
  EntityTypes,
} from "../../constant"
import "./index.scss"
import { getTissueList } from "../../../promises"
import { debounce } from "lodash"

const { Panel } = Collapse
const { RangePicker } = DatePicker
type ExpandIconPosition = "start" | "end"
const { Option } = Select
export const BuyerAndSeller = (props: FromProps) => {
  const { form, tissueList } = props
  const [whiteTissueList, setWhiteTissueList] = useState<any>([])
  const [purchaserList, setPurchaserList] = useState<any>([])

  const filterOption = (
    input: string,
    option?: { label: string; value: string; children: string }
  ) => {
    return (option?.children ?? "").toLowerCase().includes(input.toLowerCase())
  }
  const debouncedSearch = debounce(async (value, type) => {
    if (!value) {
      type == "whiteList" ? setWhiteTissueList(tissueList) : setPurchaserList(tissueList)
      return
    }
    const response = await getTissueList({ size: -1, searchCount: true, keywords: value })
    if (response.code == 200) {
      const result = response.data.records.map((ie) => {
        return {
          label: ie.name,
          value: ie.id,
        }
      })
      type == "whiteList" ? setWhiteTissueList(result) : setPurchaserList(result)
    }
  }, 500)

  const handleSearch = (value, type) => {
    debouncedSearch(value, type)
  }
  useEffect(() => {
    form.setFieldsValue({ limitAmountConfig: settlementLimit })
  }, [])
  useMemo(() => {
    if (tissueList.length > 0) {
      console.log("初始加载tissueList")
      setWhiteTissueList(tissueList)
      setPurchaserList(tissueList)
    }
  }, [tissueList])

  return (
    <>
      <div className="NewTradingScheme_Content_seller_titleText">销方限定</div>
      <div className="NewTradingScheme_Content_seller_formItem">
        <div style={{ width: "100%", display: "flex" }}>
          <Form.Item
            label="类型限定"
            name="sellerTypes"
            rules={[
              {
                required: true,
                message: "请选择类型限定",
              },
            ]}
          >
            <Checkbox.Group options={EntityTypes}></Checkbox.Group>
          </Form.Item>
          <Form.Item style={{ width: 500 }} name="sellerBlackList" label="黑名单">
            <Select
              mode="multiple"
              placeholder="请选择"
              maxTagCount={3}
              allowClear
              showSearch
              filterOption={filterOption}
            >
              {tissueList &&
                tissueList.map((item: any) => {
                  return <Option value={item.value}>{item.label}</Option>
                })}
            </Select>
          </Form.Item>
        </div>

        {/* <Form.Item<FieldType>
          name="taxRegion"
          label="税区限定"
          rules={[
            {
              required: true,
              message: "请选择税区限定",
              type: "array",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择税区限定"
            style={{ maxWidth: 646 }}
            maxTagCount="responsive"
          >
            {taxOptions &&
              taxOptions.map((item: any) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        </Form.Item> */}
        <Form.Item<FieldType>
          name="whiteList"
          label="销方名单"
          rules={[
            {
              required: true,
              message: "请选择销方名单",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择销方名单"
            style={{ maxWidth: 646 }}
            maxTagCount={5}
            allowClear
            showSearch
            onSearch={(value) => handleSearch(value, "whiteList")}
            filterOption={false}
          >
            <Option value="ALL">全部</Option>
            {whiteTissueList &&
              whiteTissueList.map((item: any) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        </Form.Item>
        <Form.Item<FieldType>
          label="数额限定"
          name="limitAmountConfig"
          rules={[
            {
              required: true,
              message: "请增加数额限定",
            },
          ]}
        >
          <span className="NewTradingScheme_Content_seller_toip">
            针对上面限定的销售方名单，限制交易额度
          </span>
        </Form.Item>
        <Form.Item<FieldType> label=" " className="NewTradingScheme_Content_seller_remove">
          <Form.List name={["limitAmountConfig"]}>
            {(subFields, subOpt) => (
              <div style={{ display: "flex", flexDirection: "column", rowGap: 16 }}>
                {subFields.map((subField) => (
                  <Space key={subField.key}>
                    <Form.Item name={[subField.name, "applyTo"]}>
                      <Select style={{ width: 175 }} placeholder="选择作用对象">
                        {applyToOptions &&
                          applyToOptions.map((item: any) => {
                            return <Option value={item.value}>{item.label}</Option>
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item name={[subField.name, "intervalType"]}>
                      <Select style={{ width: 175 }} placeholder="选择作用区间">
                        {intervalTypeOptions &&
                          intervalTypeOptions.map((item: any) => {
                            return <Option value={item.value}>{item.label}</Option>
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[subField.name, "intervalCount"]}
                      rules={[
                        {
                          required: true,
                          message: "请输入连续区间数量",
                        },
                        {
                          validator: async (rule: any, value: string) =>
                            inputDefaultRules(value, "连续区间数量", 50, false, true), //特殊字符
                        },
                      ]}
                    >
                      <Input placeholder="连续区间数量" />
                    </Form.Item>
                    <Form.Item name={[subField.name, "priceType"]}>
                      <Select style={{ width: 175 }} placeholder="选择含税价格">
                        {priceTypeOptions &&
                          priceTypeOptions.map((item: any) => {
                            return <Option value={item.value}>{item.label}</Option>
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[subField.name, "limitAmount"]}
                      rules={[
                        {
                          required: true,
                          message: "请输入最高限额",
                        },
                        {
                          validator: async (rule: any, value: string) =>
                            inputDefaultRules(value, "最高限额", 50, true), //特殊字符
                        },
                      ]}
                    >
                      <Input placeholder="最高限额" />
                    </Form.Item>
                    <Form.Item
                      name={[subField.name, "minAmount"]}
                      rules={[
                        {
                          required: true,
                          message: "请输入单位区间最小限额",
                        },
                        {
                          validator: async (rule: any, value: string) =>
                            inputDefaultRules(value, "单位区间最小限额", 50, true), //特殊字符
                        },
                      ]}
                    >
                      <Input placeholder="单位区间最小限额" />
                    </Form.Item>
                    <div style={{ marginTop: "-10px" }}>
                      <MinusCircleOutlined
                        onClick={() => {
                          subOpt.remove(subField.name)
                        }}
                      />
                    </div>
                  </Space>
                ))}
                <Button
                  type="dashed"
                  onClick={() => subOpt.add()}
                  block
                  style={{ maxWidth: 646, color: "rgba(24, 144, 255, 1)", height: 40 }}
                >
                  + 新增配置
                </Button>
              </div>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item name="requiredInvoicingCreditFlag" label="是否查询可用授信额度">
          <Radio.Group>
            <Radio value={true}>查询</Radio>
            <Radio value={false}>不查询</Radio>
          </Radio.Group>
        </Form.Item>
        <div className="NewTradingScheme_Content_seller_titleText">购方限定</div>
        <div style={{ width: "100%", display: "flex" }}>
          <Form.Item
            label="类型限定"
            name="buyerTypes"
            rules={[
              {
                required: true,
                message: "请选择类型限定",
              },
            ]}
          >
            <Checkbox.Group options={EntityTypes}></Checkbox.Group>
          </Form.Item>
          <Form.Item style={{ width: 500 }} name="buyerBlackList" label="黑名单">
            <Select
              mode="multiple"
              placeholder="请选择"
              maxTagCount={3}
              allowClear
              showSearch
              filterOption={filterOption}
            >
              {tissueList &&
                tissueList.map((item: any) => {
                  return <Option value={item.value}>{item.label}</Option>
                })}
            </Select>
          </Form.Item>
        </div>
        <Form.Item<FieldType>
          name="whiteLists"
          label="购方名单"
          rules={[
            {
              required: true,
              message: "请选择税区限定",
              type: "array",
            },
          ]}
        >
          <Select
            mode="multiple"
            placeholder="请选择"
            style={{ maxWidth: 646 }}
            maxTagCount={5}
            onSearch={(value) => handleSearch(value, "whiteLists")}
            filterOption={false}
          >
            <Option value="ALL">全部</Option>
            {purchaserList &&
              purchaserList.map((item) => {
                return <Option value={item.value}>{item.label}</Option>
              })}
          </Select>
        </Form.Item>
      </div>
    </>
  )
}

export default BuyerAndSeller
